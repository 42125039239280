import React from "react";
import "./Shine.scss";

export const Shine = () => {
  return (
    <div className="shine-wrapper">
      <div className="shine"></div>
    </div>
  );
};
